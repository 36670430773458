import * as Sentry from '@sentry/browser'
import { captureMessage } from '@sentry/browser'
import Axios from 'axios'
import { API_AUTH_URL, API_URL, OAUTH_CLIENT_ID, OAUTH_LUCKY_BIRD_CLIENT_ID } from 'config/env'
import {
  applyAuthTokenInterceptor,
  clearAuthTokens,
  IAuthTokens,
  setAuthTokens,
  TokenRefreshRequest,
} from 'utils/axios-jwt-cookies'

export const client = Axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: { 'Cache-Control': 'no-store,no-cache,must-revalidate', Pragma: 'no-cache' },
})

const oauthCredentials = {
  client_id: OAUTH_CLIENT_ID,
}

const luckyBirdCredentials = {
  client_id: OAUTH_LUCKY_BIRD_CLIENT_ID,
}

type LoginProps = {
  username: string
  password: string
}

type ValueCodeAuthProps = {
  code: string
}

type LoginPropType = LoginProps & {
  grant_type: 'password'
}

export type LoginResponseType = {
  data: {
    access_token: string
    refresh_token: string
  }
}

type LuckyBirdCodeRequest = {
  client_id: string
  grant_type: 'lucky_bird'
}

export const login = async ({ username, password }: LoginProps) => {
  const { data } = await Axios.post<LoginPropType, LoginResponseType>(API_AUTH_URL, {
    ...oauthCredentials,
    grant_type: 'password',
    username,
    password,
  })
  setAuthTokens({
    accessToken: data.access_token,
    refreshToken: data.refresh_token,
  })
}

export const luckyBirdCodeLogin = async ({ code }: ValueCodeAuthProps) => {
  const { data } = await Axios.post<LuckyBirdCodeRequest, LoginResponseType>(API_AUTH_URL, {
    client_id: luckyBirdCredentials.client_id,
    grant_type: 'lucky_bird',
    code,
  })
  setAuthTokens({
    accessToken: data.access_token,
    refreshToken: data.refresh_token,
  })
}

export const logout = async () => {
  Sentry.setUser(null)
  return clearAuthTokens()
}

const requestRefresh: TokenRefreshRequest = async (refreshToken: string): Promise<IAuthTokens | string> => {
  const response = await Axios.post(API_AUTH_URL, {
    ...oauthCredentials,
    refresh_token: refreshToken,
    grant_type: 'refresh_token',
  })

  if (response) {
    return {
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
    }
  }
  clearAuthTokens()
  return ''
}

applyAuthTokenInterceptor(client, { requestRefresh, header: 'Authorization', headerPrefix: 'Bearer ' })

client.interceptors.response.use(undefined, async (error) => {
  if (error.response) {
    const errorMessage = error.response?.data?.message || error.response?.data || error.response
    if (error.response?.status === 401 || error.response?.status === 403) {
      console.log('not authorized')
    } else {
      if (
        [500, 501, 502, 503, 504, 505, 506, 507, 508, 509, 510, 511].includes(error.response?.status) &&
        localStorage.getItem('IS_CYPRESS_ENVIRONMENT') !== 'true'
      ) {
        window.location.assign(`/error-page.html?code=${error.response?.status}&redirectUrl=${window.location.href}`)
      }
      console.error(`ERROR[${error.response?.status || error?.codeStatus}]`, errorMessage)
      captureMessage(`ERROR[${error.response?.status || error?.codeStatus}] ${errorMessage}` || 'apiClient - no error')
    }
  } else if (localStorage.getItem('IS_CYPRESS_ENVIRONMENT') !== 'true') {
    if (localStorage.getItem('RELOAD_SITE') !== 'true') {
      localStorage.setItem('RELOAD_SITE', 'true')
      window.location.reload()
    } else {
      localStorage.setItem('RELOAD_SITE', 'false')
      captureMessage(`ERROR[NO_RESPONSE] ${JSON.stringify(error)}`)
      window.location.assign(`/error-page.html?code=NO+RESPONSE&redirectUrl=${window.location.href}`)
    }
  }
  throw error
})
