import { ReactNode } from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { SectionsWrapper } from 'pages/CMS/components/SectionsWrapper'
import { SocialWallSwitcher } from 'pages/CMS/components/SocialWall/components/SocialWallSwitcher'
import { Footer } from 'theme/Footer'
import { fontFamilies, getResponsiveSize } from 'theme/styles.utils'
import { useIsMobile, useIsTablet } from 'utils/hooks/useBreakpoints'
import { useIsRoute } from 'utils/hooks/useIsRoute'
import { useSocialWall } from 'utils/hooks/useSocialWall'
import { makeStyles } from 'utils/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    padding: 0,
    fontFamily: fontFamilies.default,
    fontSize: getResponsiveSize(1.125, 'rem'),
    '& strong': {
      fontWeight: 900,
    },
  },
  section1: {
    [theme.breakpoints.down('md')]: {
      order: 1,
    },
  },
  section2: {
    [theme.breakpoints.down('md')]: {
      order: 0,
    },
  },
  section3: {
    [theme.breakpoints.down('md')]: {
      order: 2,
    },
  },
}))

interface Props {
  LeftColumnComponent: ReactNode
  MiddleColumnComponent: ReactNode
  RightColumnComponent: ReactNode
  socialWallSwitcher?: boolean
  onlyMiddleComponent?: boolean
}
export const SectionsGrid = ({
  LeftColumnComponent,
  RightColumnComponent,
  MiddleColumnComponent,
  socialWallSwitcher,
  onlyMiddleComponent,
}: Props) => {
  const { root, ...classes } = useStyles()
  const { isSocialWallActive } = useSocialWall()
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const { routeMatch } = useIsRoute(['/social-wall', '/'])
  const currentTab = routeMatch?.pathname

  const moreSpace: boolean = isSocialWallActive && socialWallSwitcher && !!currentTab

  const scrollbarPropStyles = {
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    [`&::-webkit-scrollbar`]: {
      display: 'none',
    },
  }

  const APP_BAR_HEIGHT = 96

  const stickyPropStyles = {
    position: 'sticky',
    marginTop: moreSpace ? -7 : -4,
    paddingTop: moreSpace ? 7 : 4,
    paddingBottom: 4,
    top: APP_BAR_HEIGHT,
    height: `calc(100vh - ${getResponsiveSize(APP_BAR_HEIGHT, 'px')})`,
    transition: '0.3s ease-in-out all',
  }

  const mobileStickyPropStyles = {
    paddingBottom: 8,
    overflowY: 'scroll',
    position: 'relative',
    height: `calc(100vh - ${getResponsiveSize(APP_BAR_HEIGHT, 'px')})`,
    transition: '0.3s ease-in-out all',
  }

  if (isTablet || isMobile) {
    return (
      <Box>
        <Box display="grid" gap={4} sx={{ height: APP_BAR_HEIGHT }} />
        <SectionsWrapper>
          {!onlyMiddleComponent && (
            <Box
              data-cy={`carousel-child-0`}
              className={classes.section1}
              sx={(theme) => ({
                ...mobileStickyPropStyles,
                ...scrollbarPropStyles,
                paddingTop: 2,
              })}
            >
              {LeftColumnComponent}
              {/*  No footer */}
            </Box>
          )}
          <Box
            data-cy={`carousel-child-1`}
            className={classes.section2}
            sx={(theme) => ({
              position: 'relative',
              paddingTop: moreSpace ? undefined : 2,
              paddingBottom: 4,
              overflowY: 'scroll',
              height: `calc(100vh - ${getResponsiveSize(APP_BAR_HEIGHT, 'px')})`,
              ...scrollbarPropStyles,
            })}
          >
            {moreSpace && (
              <Box sx={{ width: '100%', pb: 2 }}>
                <SocialWallSwitcher />
              </Box>
            )}
            {MiddleColumnComponent}
            <Box sx={{ width: '100%', height: (theme) => theme.spacing(4) }} />
          </Box>
          {!onlyMiddleComponent && (
            <Box
              data-cy={`carousel-child-2`}
              className={classes.section3}
              sx={(theme) => ({
                ...mobileStickyPropStyles,
                ...scrollbarPropStyles,
                paddingTop: 2,
              })}
            >
              {RightColumnComponent}
            </Box>
          )}
        </SectionsWrapper>
      </Box>
    )
  }

  return (
    <Container maxWidth="xl">
      {moreSpace ? (
        <Box display="grid" gridTemplateColumns="1fr 2fr 1fr" gap={4}>
          <Box />
          <SocialWallSwitcher />
          <Box />
        </Box>
      ) : (
        <Box display="grid" gap={4} sx={{ height: 32 }} />
      )}
      <Box display="grid" gridTemplateColumns="1fr 2fr 1fr" gap={4}>
        <Box
          className={classes.section1}
          sx={(theme) => ({
            [theme.breakpoints.up('md')]: {
              overflowY: 'scroll',
              ...stickyPropStyles,
              ...scrollbarPropStyles,
            },
          })}
        >
          {LeftColumnComponent}
          <Footer vertical />
        </Box>
        <Box
          className={classes.section2}
          sx={(theme) => ({
            [theme.breakpoints.up('md')]: {
              position: 'relative',
              ...scrollbarPropStyles,
            },
          })}
        >
          {MiddleColumnComponent}
        </Box>
        <Box
          className={classes.section3}
          sx={(theme) => ({
            [theme.breakpoints.up('md')]: {
              overflowY: 'scroll',
              ...stickyPropStyles,
              ...scrollbarPropStyles,
            },
          })}
        >
          {RightColumnComponent}
        </Box>
      </Box>
    </Container>
  )
}
