import { ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { UseInfiniteQueryResult } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { HeaderPage } from 'components/HeaderPage/HeaderPage'
import { ItemListGrid } from 'components/ItemListGrid/ItemListGrid'
import { NewHeader } from 'components/ProductGrid/NewHeader'
import { ProductGridCard } from 'components/ProductGrid/ProductGridCard'
import { TopProductBanner } from 'components/ProductGrid/TopProductBanner'
import { useShowCategoriesTree } from 'components/ShopAll/useShowCategoriesTree'
import { useHomeRSUtils } from 'pages/HomeRedemptionSite/utils'
import { WithPagination } from 'utils/api/pagination'
import { CatalogProduct } from 'utils/api/Products/CatalogProduct'
import { TranslationsKeys } from 'utils/createTranslationKey'
import { infinityQueryMap } from 'utils/hooks/infinityQueryMap'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'

import Loader from '../Loader/Loader'

type Props = (
  | {
      headingT?: TranslationsKeys
      heading: string
      headerRightComponent?: ReactNode
    }
  | {
      headingT: TranslationsKeys
      heading?: string
      headerRightComponent?: ReactNode
    }
) &
  Pick<
    UseInfiniteQueryResult<AxiosResponse<WithPagination<CatalogProduct>>>,
    'hasNextPage' | 'fetchNextPage' | 'isFetching' | 'data' | 'isLoading'
  > & {
    topProduct?: boolean
  }

export const ProductGrid = ({
  headerRightComponent,
  isLoading,
  heading,
  headingT,
  fetchNextPage,
  hasNextPage,
  isFetching,
  data,
  topProduct,
}: Props) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const { ref, inView } = useInView({ delay: 100 })
  const catalogProducts = infinityQueryMap(data)?.data || []
  const { isSingleLogic } = useIsLuckyBirdUser()
  const showCategoriesTree = useShowCategoriesTree()
  const { searchEnabled } = useHomeRSUtils()

  useEffect(() => {
    if (inView && hasNextPage) fetchNextPage()
  }, [inView, hasNextPage])

  return (
    <>
      <Grid item xs={12}>
        <Box sx={isMobile ? { mb: 2 } : { mt: showCategoriesTree && !searchEnabled ? -5 : 0, mb: 5 }}>
          <HeaderPage
            text={heading || t(headingT)}
            color="medium"
            size="medium"
            rightComponent={headerRightComponent}
          />
        </Box>
      </Grid>
      {topProduct && (
        <>
          <Grid item xs={12}>
            <NewHeader title={t('products.top')} />
          </Grid>
          <Grid item xs={12} sx={{ maxWidth: '100%' }}>
            <TopProductBanner responsive={{ xs: 2, sm: 2, md: 3, lg: 4, xl: 4, xxl: 6 }} />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ my: 4 }}>
              <Divider />
            </Box>
          </Grid>
        </>
      )}
      {isSingleLogic && (
        <Grid item xs={12} sx={isMobile ? { mb: 4 } : { mt: -4, mb: 4 }}>
          <Alert severity="info" sx={{ mt: -1 }}>
            {t('product.single_logic_info')}
          </Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <Loader
          isLoading={isLoading}
          objects={catalogProducts}
          emptyLabel={
            <Typography gutterBottom variant="h3">
              {t('app.list.no_items')}
            </Typography>
          }
        >
          <ItemListGrid lineGrid gap={isMobile ? 2 : 5} ref={ref}>
            {catalogProducts.map((catalogProduct) => (
              <ProductGridCard catalogProduct={catalogProduct} key={catalogProduct.product.id} />
            ))}
          </ItemListGrid>
          {!isMobile && !isLoading && isFetching && <Loader isLoading />}
        </Loader>
      </Grid>
    </>
  )
}
