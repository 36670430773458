import { MAIN_DOMAIN } from 'config/env'

export const setCookie = (name: string, value: string, days = 1) => {
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }

  const secureFlag = location.protocol === 'https:' ? '; Secure' : ''
  const sameSiteFlag = '; SameSite=Strict'

  document.cookie =
    name +
    '=' +
    encodeURIComponent(value || '') +
    expires +
    `; path=/; domain=${MAIN_DOMAIN}` +
    secureFlag +
    sameSiteFlag
}
export const getCookie = (name: string): string | null => {
  const nameEQ = name + '='
  const cookies = document.cookie.split(';')

  for (let cookie of cookies) {
    cookie = cookie.trim()
    if (cookie.startsWith(nameEQ)) {
      return decodeURIComponent(cookie.substring(nameEQ.length))
    }
  }

  return null
}
