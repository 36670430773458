import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { HorizontalSlider, HorizontalSliderProps } from 'components/HorizontalSlider/HorizontalSlider'
import Loader from 'components/Loader/Loader'
import { ProductInfoBar } from 'components/ProductGrid/ProductInfoBar'
import { TopProductGridCard } from 'components/ProductGrid/TopProductGridCard'
import { useTopProducts } from 'utils/hooks/api/useTopProducts'
import { useIsMobile } from 'utils/hooks/useBreakpoints'

type Props = Pick<HorizontalSliderProps, 'responsive'>

export const TopProductBanner = ({ responsive }: Props) => {
  const topProduct = useTopProducts()
  const isMobile = useIsMobile()
  const { t } = useTranslation()

  return (
    <Loader
      isLoading={topProduct?.isLoading}
      objects={topProduct?.data?.data}
      emptyLabel={
        <Typography gutterBottom variant="h3">
          {t('app.list.no_items')}
        </Typography>
      }
    >
      <Box sx={{ marginX: isMobile ? -2 : undefined }}>
        <HorizontalSlider topProducts responsive={responsive}>
          {topProduct?.data?.data?.map((catalogProduct) => (
            <TopProductGridCard catalogProduct={catalogProduct} key={catalogProduct.product.id}>
              <ProductInfoBar catalogProduct={catalogProduct} topProduct />
            </TopProductGridCard>
          ))}
        </HorizontalSlider>
        {!isMobile && !topProduct?.isLoading && topProduct?.isFetching && <Loader isLoading />}
      </Box>
    </Loader>
  )
}
