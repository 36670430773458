import { useTranslation } from 'react-i18next'
import { addYears, isBefore, isToday, isTomorrow, setYear, startOfToday } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { useDateLocale } from 'utils/hooks/useDateLocale'

export const useDate = () => {
  const { t } = useTranslation()
  const { locale } = useDateLocale()

  const printDate = (date: Date, stringDate: string) => {
    const today = startOfToday()
    let closestBirthday = setYear(date, today.getFullYear())

    if (isBefore(closestBirthday, today)) {
      closestBirthday = addYears(closestBirthday, 1)
    }

    if (isToday(closestBirthday)) {
      return t('today')
    }

    if (isTomorrow(closestBirthday)) {
      return t('tomorrow')
    }

    return formatInTimeZone(stringDate, 'Europe/Berlin', 'MMMM d', {
      // @ts-ignore
      locale,
    })
  }

  return { printDate }
}
