import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Breadcrumbs, MobileBreadcrumbs } from 'components/Breadcrumbs'
import { CountrySelectButton } from 'components/CountryContextDialog/CountrySelectButton'
import { ShopAllButton } from 'components/HeaderPage/ShopAllButton'
import { CategoriesBanner } from 'components/ProductGrid/CategoriesBanner'
import { NewHeader } from 'components/ProductGrid/NewHeader'
import { SpecialCategoryBanner } from 'components/ProductGrid/SpecialCategoryBanner'
import { TopProductBanner } from 'components/ProductGrid/TopProductBanner'
import { useShowCategoriesTree } from 'components/ShopAll/useShowCategoriesTree'
import { PointsWrapper } from 'components/WhitePaper/Toolbar'
import { WhitePaper } from 'components/WhitePaper/WhitePaper'
import { CategoriesAndFilters } from 'pages/HomeRedemptionSite/CategoriesAndFilters/CategoriesAndFilters'
import { DesktopSortComponent } from 'pages/HomeRedemptionSite/components'
import { ProductsList } from 'pages/HomeRedemptionSite/ProductsList'
import {
  getBreadcrumbsSegments,
  ROOT_CATEGORY_ID,
  useHomeRSUtils,
  useShowFiltersAndSorting,
} from 'pages/HomeRedemptionSite/utils'
import { CategoriesDrawerButton } from 'theme/Mobile/CategoriesDrawer/CategoriesDrawerButton'
import { getResponsiveSize } from 'theme/styles.utils'
import { displayPoints } from 'utils/displayPoints'
import { useSpecialSubCategories } from 'utils/hooks/api/useSpecialSubCategories'
import { useIsMobile, useIsTablet } from 'utils/hooks/useBreakpoints'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'
import { usePointsName } from 'utils/hooks/usePointsName'

// eslint-disable-next-line complexity
export const HomeRedemptionSite: FunctionComponent = () => {
  const [boxWidth, setBoxWidth] = useState(80)
  const { t, i18n } = useTranslation()
  const { showFilters } = useShowFiltersAndSorting()
  const { currentCategoriesPath, isCategoriesPage, filtersEnabled, searchEnabled, isRootCategory } = useHomeRSUtils()
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const user = useCurrentUser()
  const pointsName = usePointsName(user?.available_points)
  const { isSingleLogic } = useIsLuckyBirdUser()
  const { data: categoriesData, isLoading } = useSpecialSubCategories()

  const isProductList = !isCategoriesPage || searchEnabled || filtersEnabled
  const isCatalog = isCategoriesPage && !filtersEnabled && !searchEnabled
  const isRootCatalogAndDisabledCategoryTree = isRootCategory && !user?.show_categories_tree
  const isMainCatalogAndDisabledCategoryTree = isCategoriesPage && !user?.show_categories_tree
  const mobileBoxRef = useRef<HTMLDivElement>(null)
  const showCategoriesTree = useShowCategoriesTree()

  useEffect(() => {
    setBoxWidth(mobileBoxRef?.current?.offsetWidth)
  }, [mobileBoxRef?.current])

  if (isMainCatalogAndDisabledCategoryTree) {
    return <Navigate to={`/catalog/categories/${ROOT_CATEGORY_ID}`} />
  }

  const renderLeftHeaderSide = () => {
    switch (true) {
      case isCategoriesPage && isTablet:
        return <CategoriesDrawerButton />
      case isCategoriesPage || isRootCatalogAndDisabledCategoryTree:
        return null
      case isMobile:
        return <MobileBreadcrumbs segments={getBreadcrumbsSegments(currentCategoriesPath, t, i18n.language)} />
      case !isMobile:
        return <Breadcrumbs segments={getBreadcrumbsSegments(currentCategoriesPath, t, i18n.language)} />
      default:
        return null
    }
  }
  const leftHeaderSide = renderLeftHeaderSide()

  return (
    <WhitePaper
      defaultBgColor
      SortComponent={({ gutterTop }) => (showFilters ? <DesktopSortComponent gutterTop={gutterTop} /> : null)}
      header={
        leftHeaderSide || isTablet ? (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ minHeight: 20, maxWidth: '100%' }}
          >
            {renderLeftHeaderSide()}
            {isTablet && <CountrySelectButton />}
            {isTablet && !isSingleLogic && (
              <PointsWrapper item>
                {displayPoints(user?.available_points)} {pointsName}
              </PointsWrapper>
            )}
          </Box>
        ) : undefined
      }
    >
      <Box
        display="grid"
        gap={{ xs: 1, lg: 12 }}
        gridTemplateColumns={{
          xs: '100%',
          md:
            isCatalog || (!isCatalog && !showCategoriesTree && isSingleLogic)
              ? '100%'
              : `minmax(${getResponsiveSize(300, 'px')}, 0fr) calc(100% - ${getResponsiveSize(396, 'px')})`,
        }}
        maxWidth="100%"
        width="100%"
        data-cy={'home-redemption-site'}
        sx={{
          '& .mobile-padding .slick-track': { paddingLeft: `${boxWidth / 2 + 8}px`, transition: '0.3s all' },
        }}
      >
        {!isTablet && !isCatalog && (showCategoriesTree || !isSingleLogic) && (
          <Box>
            <CategoriesAndFilters
              showFilters={isSingleLogic ? false : showFilters}
              selectedCategoryName={
                currentCategoriesPath?.[currentCategoriesPath?.length - 1]?.translation?.[i18n.language]?.name ||
                currentCategoriesPath?.[currentCategoriesPath?.length - 1]?.name
              }
            />
          </Box>
        )}
        <Box maxWidth="100%">
          {isProductList && <ProductsList />}
          {isCatalog && (
            <Grid container mt={isMobile ? 0 : 4}>
              <Grid item xs={12}>
                <NewHeader title={t('categories.choose')} rightComponent={<ShopAllButton />} />
              </Grid>
              <Grid item xs={12} sx={{ maxWidth: '100%' }}>
                <CategoriesBanner
                  mobileBoxRef={mobileBoxRef}
                  responsive={{ xs: 1.5, sm: 2.5, md: 3, lg: 4, xl: 5, xxl: 7 }}
                />
              </Grid>
              <Grid item xs={12} mt={isMobile ? 4 : 6}>
                <NewHeader title={t('products.top')} />
              </Grid>
              <Grid item xs={12} sx={{ maxWidth: '100%' }}>
                <TopProductBanner responsive={{ xs: 1.5, sm: 2.5, md: 3, lg: 4, xl: 5, xxl: 7 }} />
              </Grid>
              {categoriesData?.data?.length >= 3 && !isLoading && (
                <>
                  <Grid item xs={12} mt={isMobile ? 4 : 6}>
                    <NewHeader title={t('categories.special_subcategories')} />
                  </Grid>
                  <Grid item xs={12} sx={{ maxWidth: '100%' }}>
                    <SpecialCategoryBanner responsive={{ xs: 1.5, sm: 2.5, md: 3, lg: 5, xl: 5, xxl: 5 }} />
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </Box>
      </Box>
    </WhitePaper>
  )
}
