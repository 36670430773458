import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { Breadcrumbs, Segment } from 'components/Breadcrumbs'
import { ProductGrid } from 'components/ProductGrid/ProductGrid'
import { useShowCategoriesTree } from 'components/ShopAll/useShowCategoriesTree'
import { WhitePaper } from 'components/WhitePaper/WhitePaper'
import { TFunction } from 'i18next'
import { CategoriesAndFilters } from 'pages/HomeRedemptionSite/CategoriesAndFilters/CategoriesAndFilters'
import { DesktopSortComponent } from 'pages/HomeRedemptionSite/components'
import { useHomeRSUtils, useShowFiltersAndSorting } from 'pages/HomeRedemptionSite/utils'
import { getResponsiveSize } from 'theme/styles.utils'
import { useBrandProducts } from 'utils/hooks/api/useBrandProducts'
import { useIsTablet } from 'utils/hooks/useBreakpoints'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'

const getBreadcrumbsSegments = (t: TFunction): Segment[] => [
  {
    id: 'catalog',
    label: t('catalog'),
    url: '/catalog',
  },
  {
    id: 'brands',
    label: t('brands'),
    url: '/catalog/brands',
  },
]

export const BrandProducts = () => {
  const productQuery = useBrandProducts()
  const { t } = useTranslation()
  const { showFilters } = useShowFiltersAndSorting()
  const { isSingleLogic } = useIsLuckyBirdUser()
  const { currentCategoriesPath, isCategoriesPage, filtersEnabled, searchEnabled, isRootCategory } = useHomeRSUtils()
  const showCategoriesTree = useShowCategoriesTree()
  const isCatalog = isCategoriesPage && !filtersEnabled && !searchEnabled
  const isTablet = useIsTablet()

  return (
    <WhitePaper
      header={<Breadcrumbs segments={getBreadcrumbsSegments(t)} />}
      SortComponent={({ gutterTop }) => (showFilters ? <DesktopSortComponent gutterTop={gutterTop} /> : null)}
    >
      <Box
        display="grid"
        gap={12}
        gridTemplateColumns={{
          xs: '100%',
          md:
            isCatalog || (!isCatalog && !showCategoriesTree && isSingleLogic)
              ? '100%'
              : `minmax(${getResponsiveSize(300, 'px')}, 0fr) calc(100% - ${getResponsiveSize(396, 'px')})`,
        }}
      >
        {!isTablet && !isCatalog && (showCategoriesTree || !isSingleLogic) && (
          <Box>
            <CategoriesAndFilters showFilters={isSingleLogic ? false : showFilters} />
          </Box>
        )}
        <Box>
          <ProductGrid headingT="brand.products" {...productQuery} />
        </Box>
      </Box>
    </WhitePaper>
  )
}
