import { useCallback, useEffect, useRef, useState } from 'react'
import ConfettiExplosion, { ConfettiProps } from 'react-confetti-explosion'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { captureException } from '@sentry/react'
import { AxiosResponse, isAxiosError } from 'axios'
import Loader from 'components/Loader/Loader'
import { useGetVoucherStatus } from 'utils/hooks/api/Vouchers/useGetVoucherStatus'
import { useReceiveVoucher } from 'utils/hooks/api/Vouchers/useReceiveVoucher'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { isSafari } from 'utils/isSafari'
import { useDisplayDateInTimeZone } from 'utils/useDisplayDateInTimeZone'

export const ReceiveVoucher = () => {
  const [size, setSize] = useState({ width: 0, height: 0 })
  const [open, setOpen] = useState(false)
  const [isNotAvailable, setIsNotAvailable] = useState(false)
  const { token } = useParams<{ token?: string }>()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { displayDateInTimeZone } = useDisplayDateInTimeZone()
  const isMobile = useIsMobile()

  const ref = useRef<HTMLElement | null>(null)

  const { data, isLoading, refetch, status, error } = useGetVoucherStatus(token)
  const { mutate, isLoading: isReceiveVoucher } = useReceiveVoucher({
    onSuccess: (data: AxiosResponse<{ url: string }>) => {
      if (isSafari()) {
        setTimeout(() => {
          window.open(data?.data?.url, '_blank') || window.location.assign(data?.data?.url)
        }, 3000)
      } else {
        setTimeout(() => {
          window.open(data?.data?.url, '_blank')
        }, 3000)
      }
      refetch()
    },
  })

  useEffect(() => {
    if (!error) return
    if (isAxiosError(error)) {
      if (error?.response?.status === 404) {
        setIsNotAvailable(true)
        return
      }
    }
    navigate(`/404`)
  }, [error])

  const handler = useCallback(() => {
    setSize({
      width: ref?.current?.offsetWidth,
      height: ref?.current?.offsetHeight,
    })
  }, [setSize])

  useEffect(() => {
    if (ref?.current !== null) {
      handler()
      window?.addEventListener('resize', handler)
    }
  }, [ref?.current])

  const voucherInfo = () => {
    try {
      return isNotAvailable
        ? t('receive_voucher.not_available')
        : data?.data?.downloaded_at
          ? t('receive_voucher.downloaded_at', {
              date: displayDateInTimeZone(data?.data?.downloaded_at) || '',
            })
          : t('receive_voucher.not_downloaded')
    } catch (e) {
      // TODO remove this in the future
      console.error(e)
      captureException(e)
      return null
    }
  }

  const largeProps: ConfettiProps = {
    force: 0.8,
    duration: 5000,
    particleCount: 300,
    width: 1600,
    colors: ['#041E43', '#1471BF', '#5BB4DC', '#FC027B', '#66D805'],
    // onComplete: () => {
    //   mutate(token)
    // },
  }

  return (
    <Box
      ref={ref}
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={(theme) => ({
        height: 'calc(100vh - 80px)',
        width: '100%',
        overflow: 'hidden',
        [theme.breakpoints.down('lg')]: {
          marginTop: -16,
          height: '100vh',
        },
      })}
    >
      <Grid item>
        <Loader isLoading={isLoading}>
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="h4">{voucherInfo()}</Typography>
            </Grid>
            <Grid item>
              <LoadingButton
                variant="contained"
                onClick={() => {
                  setOpen(true)
                  mutate(token)
                  setTimeout(() => setOpen(false), 10000)
                }}
                disabled={open || isReceiveVoucher || isNotAvailable}
              >
                {t('receive_voucher.download_voucher')}
                {open && (
                  <Box sx={{ position: 'absolute' }}>
                    <ConfettiExplosion height={'200vh'} {...largeProps} />
                  </Box>
                )}
              </LoadingButton>
            </Grid>
          </Grid>
        </Loader>
      </Grid>
      {/* <Box*/}
      {/*  sx={{*/}
      {/*    position: 'fixed',*/}
      {/*    left: isMobile ? 0 : '25%',*/}
      {/*    top: 0,*/}
      {/*    ['& > canvas']: {*/}
      {/*      width: '100vw',*/}
      {/*      minHeight: '100vh',*/}
      {/*    },*/}
      {/*  }}*/}
      {/* >*/}
      {/*  {open && <ConfettiExplosion height="100vh" {...largeProps} />}*/}
      {/* </Box>*/}
    </Box>
  )
}
