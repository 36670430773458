import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material'
import Grid from '@mui/material/Grid'
import { Birthday } from 'components/ArteelIcons'
import Avatar from 'components/Avatar'
import Loader from 'components/Loader/Loader'
import { useUpcomingBirthdays } from 'utils/hooks/api/Birthdays/useUpcomingBirthdays'

import { ContentCard } from './ContentCard'
import { useDate } from './helpers/useDate'

const FullNameContainer = styled(Grid)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.h3.fontSize,
}))

const DateContainer = styled(Grid)(({ theme }) => ({
  fontSize: theme.typography.h4.fontSize,
}))

export const UpcomingBirthdays = () => {
  const { data, isFetching } = useUpcomingBirthdays()
  const { t } = useTranslation()
  const { printDate } = useDate()

  return (
    <ContentCard title={t('birthdays')} icon={<Birthday />} link="/birthdays" dataCy="upcoming-birthdays-widget">
      <Loader isLoading={isFetching} objects={data?.data}>
        <Grid container gap={2} pb={2}>
          {data?.data.map((birthday, index) => (
            <Grid container key={birthday.id} gap={2} alignItems="center" data-cy={`person-${index}`}>
              <Grid item>
                <Avatar fullName={birthday.full_name} image={birthday.avatar} width={45} height={45} fontSize={1.5} />
              </Grid>
              <Grid item container xs alignItems="center" spacing={0.5}>
                <FullNameContainer item xs={12} alignSelf="flex-start">
                  {birthday.full_name}
                </FullNameContainer>
                <DateContainer item xs={12} alignSelf="flex-end" data-cy="birth-date">
                  {birthday.birth_date && printDate(new Date(birthday.birth_date), birthday.birth_date)}
                </DateContainer>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Loader>
    </ContentCard>
  )
}
