import * as Sentry from '@sentry/browser'
import { APP_VERSION, ENVIRONMENT, MAIN_DOMAIN } from 'config/env'
import { BackendModule, InitOptions, ReadCallback } from 'i18next'
import LocalStorageBackend from 'i18next-localstorage-backend'
import resourcesToBackend from 'i18next-resources-to-backend'
import isEmpty from 'lodash/isEmpty'
import { getRequest } from 'utils/api/apiRequest'

export const backendLoader: BackendModule = {
  type: 'backend',
  init: () => {},
  read: async (language: string, namespace: string, callback: ReadCallback) => {
    let mergedTranslation = {}
    try {
      try {
        // fetch from default file
        const { data: defaultEnglish } = await getRequest<{ [key: string]: string }>(`/translations/default`)
        const { data: translatedEnglish } = await getRequest<{ [key: string]: string }>(`/languages/content/en`)
        mergedTranslation = { ...defaultEnglish, ...translatedEnglish, ...mergedTranslation }
      } catch (error) {
        Sentry.captureMessage(JSON.stringify(error || 'i18n redemption - catch in default translation'))
      }
      // fetch from api
      const { data } = await getRequest<{ [key: string]: string }>(`/languages/content/${language}`)
      const translationWithoutEmptyString = Object.fromEntries(
        Object.entries(data).filter((translation) => !isEmpty(translation[1]))
      )
      mergedTranslation = { ...mergedTranslation, ...translationWithoutEmptyString }
    } catch (error) {
      Sentry.captureMessage(JSON.stringify(error || 'i18n redemption - catch in translation'))
    }
    await callback(null, mergedTranslation)
  },
}
export const options = {
  debug: process.env.NODE_ENV !== 'production',
  keySeparator: false, // we do not use keys in form messages.welcome
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  queryParameter: 'lang',
  // have a common namespace used around the full app
  ns: ['translation'],
  fallbackNS: 'translation',
  defaultNS: 'translation',
  react: {
    useSuspense: true,
  },

  transSupportBasicHtmlNodes: true,
  transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p'],

  // order and from where user language should be detected
  order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: MAIN_DOMAIN,

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  backend: {
    allowMultiLoading: true,
    backends: [
      LocalStorageBackend,
      backendLoader,
      resourcesToBackend((lng: string, ns: string, clb: ReadCallback) => {
        import(`./translations/en.json`)
          .then((resources) => {
            clb(null, resources)
          })
          .catch((error) => {
            clb(error, {})
          })
      }),
    ],
    backendOptions: [
      {
        defaultVersion: APP_VERSION,
        expirationTime: ENVIRONMENT !== 'prod' ? 60 * 1000 : 1 * 24 * 60 * 60 * 1000, // if local, 1 minute, else 1 day
        store: typeof window !== 'undefined' ? window.localStorage : null,
      },
    ],
  },
} as InitOptions
