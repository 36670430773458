import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { ListChildComponentProps, VariableSizeList as VirtualizedList } from 'react-window'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { Close, Search } from 'components/ArteelIcons'
import { TextField } from 'components/TextField/TextField'
import debounce from 'lodash/debounce'
import { BrandRow } from 'pages/HomeRedemptionSite/CategoriesAndFilters/components/BrandRow'
import qs from 'query-string'
import { fontFamilies, getResponsiveSize } from 'theme/styles.utils'
import { Brand } from 'utils/api/Brands/fetchBrands'
import { useBrands } from 'utils/hooks/api/useBrands'
import { useIsMobile, useIsTablet } from 'utils/hooks/useBreakpoints'
import { important } from 'utils/styles'

type Props = {
  onClose: (checked: number[]) => void
  open: boolean
}

const otherKey = '1'
const alphabetChars = 'abcdefghijklmnopqrstuvwxyz'
const alphabet = alphabetChars.concat(otherKey).split('')

export const BrandsDialog = ({ onClose, open }: Props) => {
  const location = useLocation()
  const params = qs.parse(location.search.replace('?', ''))
  const singleSelected = params['filters[product.brand.id|eq]'] as string
  const multiSelected = params['filters[product.brand.id]'] as string
  const checkedBrandIds = singleSelected ? [Number(singleSelected)] : multiSelected?.split(',')?.map(Number) || []
  const [checked, setChecked] = useState<number[]>(checkedBrandIds)
  const [textFieldText, setTextFieldText] = useState('')
  const isTablet = useIsTablet()
  const { t } = useTranslation()
  const { data } = useBrands({ params: 'limit=1000&sort[name]=asc' })
  const brands = useMemo(() => data?.data?.data, [data])
  const selectedBrands = useMemo(() => brands?.filter((brand) => checked.includes(brand.id)), [brands, checked])
  const isMobile = useIsMobile()
  const inputRef = useRef<HTMLInputElement>()

  useEffect(() => {
    setChecked(checkedBrandIds)
  }, [singleSelected, multiSelected])

  const handleToggle = (value: number) => {
    setChecked((prevState) =>
      prevState.includes(value) ? prevState.filter((item) => item !== value) : [...prevState, value]
    )
  }

  const onChangeText = debounce((text: string) => {
    setTextFieldText(text)
  }, 700)

  const filteredBrands = useMemo(() => {
    return brands?.filter((brand) => brand.name.toLowerCase().includes(textFieldText.toLowerCase())) || []
  }, [brands, textFieldText])

  const groupedBrands = useMemo(() => {
    return alphabet.reduce(
      (acc, letter) => {
        acc[letter] = filteredBrands.filter((brand) => {
          const firstLetter = brand.name.trimStart()[0]?.toLowerCase()
          return letter === otherKey ? !alphabetChars.includes(firstLetter) : firstLetter === letter
        })
        return acc
      },
      {} as Record<string, Brand[]>
    )
  }, [filteredBrands])

  const itemList: { type: string; brand?: Brand; text?: string }[] = useMemo(() => {
    return alphabet.flatMap((letter) => {
      const brandsInGroup = groupedBrands[letter]
      if (!brandsInGroup || brandsInGroup.length === 0) return []
      return [{ type: 'header', text: letter }, ...brandsInGroup.map((brand) => ({ type: 'brand', brand }))]
    })
  }, [groupedBrands])

  const getItemSize = (index: number) => {
    return itemList[index].type === 'header' ? 50 : 40
  }

  const renderRow = ({ index, style }: ListChildComponentProps) => {
    const item = itemList[index]
    if (item.type === 'header') {
      return (
        <Typography
          key={item.text}
          style={style}
          sx={{
            backgroundColor: (theme) => theme.palette.grey[200],
            padding: '8px',
            fontWeight: 900,
            fontSize: getResponsiveSize(1.5, 'rem'),
            lineHeight: '32px',
            borderTop: (theme) => `2px solid ${theme.palette.divider}`,
            borderBottom: (theme) => `2px solid ${theme.palette.divider}`,
          }}
        >
          {item.text === otherKey ? t('app.other') : item.text.toUpperCase()}
        </Typography>
      )
    } else if (item.type === 'brand') {
      return (
        <BrandRow
          style={style}
          key={item.brand.id}
          item={item.brand}
          handleToggle={handleToggle}
          isChecked={checked.includes(item.brand.id)}
        />
      )
    }
    return null
  }

  return (
    <Dialog
      open={open}
      onClose={() => onClose(checked)}
      maxWidth="md"
      fullWidth={isTablet}
      sx={{
        [`& .MuiDialog-paper`]: {
          width: '100%',
          minHeight: '746px',
          maxHeight: '100vh',
          p: isMobile ? 2 : 4,
          borderRadius: 8,
        },
      }}
    >
      <DialogTitle>
        <Box
          display="grid"
          gap={{ xs: 0, md: 4 }}
          gridTemplateColumns={{ xs: 'auto', md: 'auto minmax(500px, 1fr)' }}
          alignContent="center"
          alignItems="center"
        >
          <Typography
            fontSize={getResponsiveSize(3, 'rem')}
            fontFamily={fontFamilies.cond}
            fontWeight={900}
            align={isMobile ? 'center' : undefined}
          >
            {t('brands')}
          </Typography>
          <TextField
            inputRef={inputRef}
            size="small"
            fullWidth
            placeholder={t('app.search')}
            slotProps={{
              input: {
                endAdornment: (
                  <IconButton size="small" onClick={() => onChangeText(inputRef?.current?.value || '')}>
                    <Search color="primary" />
                  </IconButton>
                ),
              },
              htmlInput: { sx: { padding: important(`8px 16px`) } },
            }}
            onChange={(event) => onChangeText(event.target.value)}
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Box display="flex" mb={checked.length ? 2 : 0} flexWrap="wrap" width="100%">
            {selectedBrands?.map((brand) => (
              <Box
                key={brand.id}
                sx={{
                  position: 'relative',
                  mr: 4,
                  fontSize: getResponsiveSize(1.5, 'rem'),
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                {brand.name}
                <Box
                  onClick={() => handleToggle(brand.id)}
                  sx={{
                    position: 'absolute',
                    top: '-12px',
                    right: '-16px',
                    cursor: 'pointer',
                    color: (theme) => theme.palette.primary.main,
                    '& svg': {
                      fontSize: getResponsiveSize(0.6, 'rem'),
                    },
                  }}
                >
                  <Close />
                </Box>
              </Box>
            ))}
          </Box>
          {!itemList?.length && (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Typography variant="h3">{t('app.no_items_found')}</Typography>
            </Box>
          )}
          <VirtualizedList height={500} itemCount={itemList.length} itemSize={getItemSize} width="100%">
            {renderRow}
          </VirtualizedList>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', gap: 4, mt: 2 }}>
            <Button variant="contained" size="small" color="primary" onClick={() => onClose(checked)}>
              {t('app.filters.apply')}
            </Button>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={() => {
                setChecked([])
                onClose([])
              }}
            >
              {t('app.filters.no_defined')}
            </Button>
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}
