import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { HorizontalSlider, HorizontalSliderProps } from 'components/HorizontalSlider/HorizontalSlider'
import Loader from 'components/Loader/Loader'
import { CategoryGridCard } from 'pages/TopRatedProducts/CategoryGridCard'
import { useSpecialSubCategories } from 'utils/hooks/api/useSpecialSubCategories'
import { useIsMobile } from 'utils/hooks/useBreakpoints'

type Props = Pick<HorizontalSliderProps, 'responsive'>

export const SpecialCategoryBanner = ({ responsive }: Props) => {
  const { data: categoriesData, isLoading, isFetching } = useSpecialSubCategories()
  const categories = categoriesData?.data || []
  const isMobile = useIsMobile()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const categoryLink = (categoryId: number) => {
    navigate(`/catalog/categories/${categoryId}`)
    window.scroll({ top: 0, behavior: 'smooth' })
  }

  return (
    <Loader
      isLoading={isLoading}
      objects={categories}
      emptyLabel={
        <Typography gutterBottom variant="h3">
          {t('app.list.no_items')}
        </Typography>
      }
    >
      <Box sx={{ marginX: isMobile ? -2 : undefined }}>
        <HorizontalSlider topProducts responsive={responsive} disableArrow>
          {categories?.map((specialCategory) => (
            <CategoryGridCard
              category={specialCategory?.category}
              onClick={() => categoryLink(specialCategory?.category.id)}
              key={specialCategory?.category.id}
              banner
              round
            />
          ))}
        </HorizontalSlider>
        {!isMobile && !isLoading && isFetching && <Loader isLoading />}
      </Box>
    </Loader>
  )
}
