import { ReactNode, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { inputBaseClasses } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import { Search as SearchIcon } from 'components/ArteelIcons'
import { CypressButtonLogout } from 'components/CypressButtons/CypressButton'
import { TextField } from 'components/TextField/TextField'
import { RecognizeSomeoneDrawerButton } from 'pages/RecognizeSomeone'
import { useSubdomainInfo } from 'shared/Site/api'
import { SiteType } from 'shared/Site/siteType'
import SearchContext from 'store/SearchContext'
import { useThemeContext } from 'store/ThemeContext'
import { ScrollUp } from 'theme/ScrollUp/ScrollUp'
import { getResponsiveSize } from 'theme/styles.utils'
import { LogoWeb } from 'theme/Web/LogoWeb'
import { MenuWeb } from 'theme/Web/Menu/Menu'
import { useLBThemes } from 'utils/api/Theme/useThemes'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { useIsMobile, useIsTablet } from 'utils/hooks/useBreakpoints'
import { useIsCypress } from 'utils/hooks/useIsCypress'
import { useIsRoute } from 'utils/hooks/useIsRoute'
import { useResize } from 'utils/hooks/useResize'
import { useSocialWall } from 'utils/hooks/useSocialWall'
import { important, inside } from 'utils/styles'
import { makeSx } from 'utils/styles/makeSx'

type Props = {
  children: ReactNode | string
  TopbarMobile: ReactNode
  topBarGutter?: boolean
  hasLuckyBirdImageBg?: boolean
}

export default ({ children, TopbarMobile, topBarGutter, hasLuckyBirdImageBg }: Props) => {
  const isTablet = useIsTablet()
  const isMobile = useIsMobile()
  const { data: luckyBirdTheme } = useLBThemes()
  const { isRoute: isRouteWithImgBg } = useIsRoute(['/luckybird', '/'])
  const { isCypress } = useIsCypress()

  const { data: subdomainInfo } = useSubdomainInfo()
  const { isSocialWallActive } = useSocialWall()
  const [ref, setRef] = useState(null)
  const { height } = useResize(ref)
  const { setAppBarHeight } = useThemeContext()
  const { t } = useTranslation()
  const { currentValueSearch, onChangeSearch } = useContext(SearchContext)
  const location = useLocation()
  const isActiveSearch = location.pathname.includes('/catalog')
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!height) return
    setAppBarHeight(height)
  }, [height])

  const defaultTemplateSx = makeSx((_) => ({
    background: _.palette.background.default,
    height: '100%',
    maxWidth: '100%',
    fontFamily: 'ProximaNovaCond',
  }))
  const imageTemplateSx = makeSx((_) => ({
    minHeight: '100%',
    maxWidth: '100%',
    fontFamily: 'ProximaNovaCond',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundColor: _.palette.background.default,
    backgroundImage: `url('${luckyBirdTheme?.theme?.background}')`,
  }))

  return (
    <Box sx={!(hasLuckyBirdImageBg && isRouteWithImgBg) ? defaultTemplateSx : imageTemplateSx}>
      <CssBaseline />
      <Grid container>
        <Grid item xs={12}>
          <AppBar
            ref={setRef}
            position="fixed"
            color="inherit"
            sx={(theme) => ({
              height: theme.spacing(12),
              justifyContent: 'center',
              borderBottom: `${isMobile ? 4 : 2}px solid ${theme.palette.divider}`,
            })}
            elevation={isMobile ? 0 : 1}
          >
            {!isTablet && (
              <Box>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid container item xs={10} spacing={2} alignItems="center">
                    <Grid item lg>
                      <LogoWeb />
                    </Grid>
                    <Grid item lg={isActiveSearch ? true : 'auto'}>
                      {subdomainInfo?.data?.type === SiteType.cms && isSocialWallActive && (
                        <RecognizeSomeoneDrawerButton />
                      )}
                      {isActiveSearch && (
                        <TextField
                          inputRef={inputRef}
                          sx={(t) => ({
                            [inside(inputBaseClasses.root)]: {
                              background: important(t.palette.background.default),
                            },
                            [`& .Mui-focused input::placeholder`]: {
                              visibility: 'hidden',
                            },
                          })}
                          size="small"
                          variant="outlined"
                          fullWidth
                          center
                          placeholder={t('app.search_placeholder')}
                          defaultValue={currentValueSearch}
                          onChange={(event) => onChangeSearch(event.target.value)}
                          slotProps={{
                            input: {
                              endAdornment: (
                                <IconButton
                                  color="primary"
                                  sx={{ mr: 0.75 }}
                                  onClick={() => onChangeSearch(inputRef?.current?.value)}
                                >
                                  <SearchIcon color="primary" />
                                </IconButton>
                              ),
                            },
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item lg>
                      <MenuWeb />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
            {isTablet && <Box>{TopbarMobile}</Box>}
          </AppBar>
        </Grid>
        <Grid item xs={12} sx={{ pt: valueOrUndefined(topBarGutter, getResponsiveSize(96, 'px')) }}>
          {children}
        </Grid>
      </Grid>
      <ScrollUp />
      {isCypress && <CypressButtonLogout />}
    </Box>
  )
}
