import { FC, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { accordionSummaryClasses, useTheme } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import MuiLink from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { CloseContained, Plus } from 'components/ArteelIcons'
import { Minus } from 'components/ArteelIcons/Minus'
import { HeaderPage } from 'components/HeaderPage/HeaderPage'
import { fontFamilies, getResponsiveSize } from 'theme/styles.utils'
import { isLoggedIn } from 'utils/axios-jwt-cookies'
import { useIsMobile } from 'utils/hooks/useBreakpoints'

import contextEn from './content_en.json'
import contentFr from './content_fr.json'
import contentNl from './content_nl.json'

type Props = {
  withoutBack?: boolean
}

export const FAQ = ({ withoutBack }: Props) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const location = useLocation()
  const isLuckybird = location.pathname.includes('luckybird')

  const content = () => {
    switch (i18n.language) {
      case 'fr':
        return contentFr
      case 'nl':
        return contentNl
      default:
        return contextEn
    }
  }

  const onClose = () => {
    if (isLuckybird) {
      navigate(isLoggedIn() ? '/' : '/auth/luckybird')
    } else {
      navigate(isLoggedIn() ? '/' : '/auth/login')
    }
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="flex-start"
      maxWidth={getResponsiveSize(700, 'px')}
      px={isMobile ? 0.5 : 4}
      position="relative"
      mt={!isLoggedIn() && isMobile ? -10 : isLoggedIn() ? 0 : 2}
    >
      {!withoutBack && (
        <Box position="absolute" top={-16} right={-16}>
          <IconButton onClick={onClose} aria-label="close" size="small">
            <CloseContained fontSize="medium" color="primary" />
          </IconButton>
        </Box>
      )}
      <Grid container spacing={isMobile ? 3 : 6} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <HeaderPage
            text={t('faq.header')}
            sx={{ pt: 1, pb: 1, textTransform: 'inherit', lineHeight: 1 }}
            color="medium"
            size="small"
          />
          <Typography variant="h3" align="center">
            {t('faq.subheader')}
          </Typography>
        </Grid>
        {content()?.subjects?.map((subject, index) => (
          <Grid
            key={index}
            item
            xs={12}
            sx={(theme) => ({
              width: '100%',
              padding: theme.spacing(1, 0),
              '& > .MuiAccordion-root': {
                '&:first-of-type': {
                  borderTop: `${isMobile ? 1 : 2}px solid ${theme.palette.grey[400]}`,
                },
                borderBottom: `${isMobile ? 1 : 2}px solid ${theme.palette.grey[400]}`,
                borderRadius: '0!important',
                boxShadow: 'none',
                margin: 0,
                padding: 0,
                transition: '0.3s all',
                '&.Mui-expanded': {
                  padding: 0,
                  margin: 0,
                  transition: '0.3s all',
                },
                '&:before': {
                  display: 'none',
                },
                '&:last-child': {
                  margin: 0,
                },
              },
            })}
          >
            <Typography variant="h3" fontWeight="bold" align="center" mb={isMobile ? 1 : 3}>
              {subject.title}
            </Typography>
            {subject?.questions?.map((item, itemIndex) => (
              <AccordionWrapper title={item.question} key={`item-${index}-${itemIndex}`}>
                <BBCodeParser bbCode={item.answer} />
              </AccordionWrapper>
            ))}
          </Grid>
        ))}
        <Grid item xs={12} mx={isMobile ? 0 : 8} mb={2}>
          <Box display="grid" gap={2} className={isMobile ? undefined : 'spacer'}>
            <Typography
              align="center"
              sx={{
                fontSize: `${getResponsiveSize(2, 'rem')}!important`,
                color: (theme) => theme.palette.grey[700],
                fontWeight: '900',
                lineHeight: 1,
              }}
            >
              {t('faq.not_answer')}
            </Typography>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                navigate(isLoggedIn() ? '/service-request' : isLuckybird ? '/auth/luckybird/help' : '/auth/help')
                setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 200)
              }}
            >
              {t('faq.go_to_contact')}
            </Button>
            <Typography
              align="center"
              sx={{
                fontSize: `${getResponsiveSize(1, 'rem')}!important`,
                lineHeight: 1,
                fontWeight: 'bold',
              }}
            >
              {t('faq.or_email')}&nbsp;
              <MuiLink to="mailto:support@arteel.com" component={Link} target="_blank">
                support@arteel.com
              </MuiLink>
              .
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

const AccordionWrapper = ({ title, children }: { title: string; children: ReactNode }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <Accordion square expanded={expanded} onChange={() => setExpanded((prev) => !prev)}>
      <AccordionSummary
        expandIcon={expanded ? <Minus color="primary" fontWeight="bold" /> : <Plus color="primary" fontWeight="bold" />}
        sx={{
          [`&.${accordionSummaryClasses.root}`]: {
            minHeight: 'inherit!important',
            padding: (theme) => theme.spacing(2, 4),
          },
          [`& .${accordionSummaryClasses.content}`]: {
            [`&.${accordionSummaryClasses.expanded}`]: {
              margin: 0,
            },
          },
        }}
      >
        <Typography variant="h3" fontWeight="bold" color="primary.main" align="left" lineHeight={1}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          py: 0,
          fontSize: (theme) => theme.typography.body2.fontSize,
          fontFamily: fontFamilies.default,
          '& p:first-of-type': {
            marginTop: 0,
          },
          '& ul': {
            marginBlockStart: -2,
            paddingInlineStart: 2,
          },
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

interface BBCodeParserProps {
  bbCode: string
}

const BBCodeParser: FC<BBCodeParserProps> = ({ bbCode }) => {
  const theme = useTheme()
  const parseBBCode = (text: string): string => {
    // Replace [b]...[/b] with <strong>...</strong>
    text = text.replace(/\[b\](.*?)\[\/b\]/gi, '<strong>$1</strong>')

    // Replace [i]...[/i] with <em>...</em>
    text = text.replace(/\[i\](.*?)\[\/i\]/gi, '<em>$1</em>')

    // Replace [mailto=...]...[/mailto] with <a href="...">...</a>
    text = text.replace(
      /\[mailto=(.*?)\](.*?)\[\/mailto\]/gi,
      '<a style="color: ' + theme.palette.primary.main + ';" href="mailto:$1">$2</a>'
    )
    // Replace [tel=...]...[/tel] with <a href="...">...</a>
    text = text.replace(
      /\[tel=(.*?)\](.*?)\[\/tel\]/gi,
      '<a style="color: ' + theme.palette.primary.main + ';" href="tel:$1">$2</a>'
    )
    // Replace [url=...]...[/url] with <a href="...">...</a>
    text = text.replace(
      /\[url=(.*?)\](.*?)\[\/url\]/gi,
      '<a style="color: ' + theme.palette.primary.main + ';" href="$1" target="_blank" rel="noopener noreferrer">$2</a>'
    )

    // Replace [list]...[/list] with <ul>...</ul> and [li]...[/li] with <li>...</li>
    text = text.replace(/\[list\](.*?)\[\/list\]/gis, '<ul>$1</ul>')
    text = text.replace(/\[li\](.*?)\[\/li\]/gi, '<li>$1</li>')

    // Wrap text in <p> tags (split by double new lines)
    text = text
      .split(/(?:\r?\n){2,}/g) // Split by two or more new lines
      .map((paragraph) => `<p>${paragraph.trim()}</p>`)
      .join('')

    // Handle new lines (\n) by replacing them with <br />
    text = text.replace(/\n/g, '<br />')

    return text
  }

  return <div dangerouslySetInnerHTML={{ __html: parseBBCode(bbCode) }} />
}
