const {
  REACT_APP_API_URL,
  REACT_APP_CLIENT_ID,
  REACT_APP_MAIN_DOMAIN,
  REACT_APP_ADMIN_PANEL_URL,
  REACT_APP_PROTOCOL,
  REACT_APP_LUCKY_BIRD_CLIENT_ID,
} = process.env

export type EnvironmentType = 'local' | 'dev' | 'staging' | 'prod'

const getErrorMessage = (name: string) =>
  console.info(`no environment variable ${name}, default environment variable loaded`)

const checkApiUrl = () => {
  getErrorMessage('REACT_APP_API_URL')
  return 'localhost'
}

const checkClientId = () => {
  getErrorMessage('REACT_APP_CLIENT_ID')
  return ''
}

const checkLuckyBirdClientId = () => {
  getErrorMessage('REACT_APP_LUCKY_BIRD_CLIENT_ID')
  return ''
}

const checkMainDomain = () => {
  getErrorMessage('REACT_APP_MAIN_DOMAIN')
  return 'arteel.be'
}

const checkProtocol = () => {
  getErrorMessage('REACT_APP_PROTOCOL')
  return 'http'
}

const checkSentryDNS = () => {
  getErrorMessage('REACT_APP_SENTRY_DNS')
  return ''
}

const checkEnvironment = (): EnvironmentType => {
  getErrorMessage('REACT_APP_ENVIRONMENT')
  return 'dev'
}

const checkAppVersion = () => {
  getErrorMessage('REACT_APP_VERSION')
  return 'no-defined-version'
}

const checkAdminPanelUrl = () => {
  getErrorMessage('REACT_APP_ADMIN_PANEL_URL')
  return 'localhost'
}

export const PROTOCOL = REACT_APP_PROTOCOL ?? checkProtocol()
export const API_URL = `${PROTOCOL}://${REACT_APP_API_URL ?? checkApiUrl()}/api`
export const API_AUTH_URL = `${API_URL}/oauth2/token`
export const OAUTH_CLIENT_ID = REACT_APP_CLIENT_ID ?? checkClientId()
export const OAUTH_LUCKY_BIRD_CLIENT_ID = REACT_APP_LUCKY_BIRD_CLIENT_ID ?? checkLuckyBirdClientId()
export const MAIN_DOMAIN = REACT_APP_MAIN_DOMAIN ?? checkMainDomain()
export const ADMIN_PANEL_URL = REACT_APP_ADMIN_PANEL_URL ?? checkAdminPanelUrl()
export const MEDIA_URL = `${PROTOCOL}://${REACT_APP_API_URL ?? checkApiUrl()}`
export const SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS ?? checkSentryDNS()
export const ENVIRONMENT: EnvironmentType = (process.env.REACT_APP_ENVIRONMENT as EnvironmentType) ?? checkEnvironment()
export const APP_VERSION = process.env.REACT_APP_VERSION ?? checkAppVersion()
export const BPOST_API = `${PROTOCOL}://pudo.bpost.be`
