import { ReactNode, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Search from '@mui/icons-material/Search'
import { badgeClasses } from '@mui/material'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import { ShoppingBasket } from 'components/ArteelIcons'
import isEmpty from 'lodash/isEmpty'
import { MobileMenuButton } from 'pages/CMS/components/MobileMenuButton'
import { useShowFiltersAndSorting } from 'pages/HomeRedemptionSite/utils'
import { useSelectShoppingCart } from 'shared/ShoppingCart/slice'
import SearchContext from 'store/SearchContext'
import { FilterAndSearchMobile } from 'theme/Mobile/FilterAndSearch/FilterAndSearchMobile'
import { ProductFilterSortMobile } from 'theme/Mobile/FilterAndSearch/ProductFilterSortMobile'
import { LogoWeb } from 'theme/Web/LogoWeb'
import { inside } from 'utils/styles'

import { TopBarGridMobile } from './TopBarGridMobile'

export const ProjectTopbarMobile = () => {
  const { data: shoppingCartItems } = useSelectShoppingCart()
  const quantityArray = shoppingCartItems?.data?.items?.map((value) => value.quantity)
  const { showFilters } = useShowFiltersAndSorting()
  const navigate = useNavigate()

  const { onToggleSearch } = useContext(SearchContext)
  const renderBasket = () => (
    <Badge
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      max={99}
      sx={{
        [inside(badgeClasses.badge)]: {
          lineHeight: '20px',
        },
      }}
      badgeContent={isEmpty(quantityArray) ? 0 : quantityArray.reduce((prev, curr) => prev + curr) || 0}
      color="primary"
      onClick={() => navigate('/shopping-cart')}
    >
      <ShoppingBasket color="primary" sx={{ mr: 2 }} />
    </Badge>
  )

  const { LeftComponent, RightComponent, MidComponent }: Record<string, ReactNode> = showFilters
    ? {
        LeftComponent: (
          <Box display="grid" ml={1}>
            <LogoWeb />
          </Box>
        ),
        MidComponent: null,
        RightComponent: (
          <Box display="flex">
            <FilterAndSearchMobile
              basket={renderBasket()}
              showFiltersBadge={false}
              search={
                <IconButton size="small" onClick={onToggleSearch}>
                  <Search color="primary" fontSize="large" />
                </IconButton>
              }
              filters={<ProductFilterSortMobile />}
            />
            <Box>
              <MobileMenuButton />
            </Box>
          </Box>
        ),
      }
    : {
        LeftComponent: (
          <Box display="grid" ml={1}>
            <LogoWeb />
          </Box>
        ),
        MidComponent: null,
        RightComponent: (
          <Grid container alignItems="center" spacing={2} wrap="nowrap">
            <Grid item>
              <IconButton size="small" onClick={onToggleSearch}>
                <Search color="primary" fontSize="large" />
              </IconButton>
            </Grid>
            <Grid item>{renderBasket()}</Grid>
            <Grid item sx={{ ml: -2 }}>
              <MobileMenuButton />
            </Grid>
          </Grid>
        ),
      }

  return <TopBarGridMobile LeftComponent={LeftComponent} MidComponent={MidComponent} RightComponent={RightComponent} />
}
