import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import NoMatch from 'components/NoMatch'
import { HelpWrapper } from 'pages/CMS/components/ContentBox/ServiceRequest/HelpWrapper'
import { FAQ } from 'pages/Contact/FAQ'
import { Activate } from 'pages/Login/Activate'
import { ActivateAccount } from 'pages/Login/ActivateAccount/ActivateAccount'
import { Confirmation } from 'pages/Login/ActivateAccount/Confirmation'
import { AuthRedirect } from 'pages/Login/AuthRedirect'
import { ForgotPassword } from 'pages/Login/ForgotPassword'
import { LoginPage } from 'pages/Login/Login'
import { Logout } from 'pages/Login/Logout'
import { LuckyBirdCodeLogin } from 'pages/Login/LuckyBird/LuckyBirdCodeLogin'
import { useIsLuckyBirdGlobalAuth } from 'pages/Login/LuckyBird/useIsLuckyBirdGlobalAuth'
import { PrivacyPolicy } from 'pages/Login/PrivacyPolicy'
import { ResetPassword } from 'pages/Login/ResetPassword'
import { OktaErrors } from 'pages/Login/SSO/OktaErrors'
import { ReceiveVoucher } from 'pages/ReceiveVoucher/ReceiveVoucher'
import { useSubdomainInfo } from 'shared/Site/api'
import { AuthProvider } from 'store/AuthContext'
import { CustomAuthProvider } from 'store/CustomAuthTokenProvider'
import { SearchProvider } from 'store/SearchContext'
import { AuthTemplate } from 'theme/AuthTemplate'
import { useIsMobile } from 'utils/hooks/useBreakpoints'

import { ProtectedSwitch } from './ProtectedSwitch'

export const AppRoutes = () => {
  const { data: subdomainInfo, isFetching } = useSubdomainInfo()
  const isGlobalPageLB = useIsLuckyBirdGlobalAuth()
  const location = useLocation()
  const isMobile = useIsMobile()

  const sharedRoutes = {
    help: () => <Route path="/help" Component={HelpWrapper} />,
    privacy: () => <Route path="/privacy-and-policy" Component={PrivacyPolicy} />,
    faq: () => (
      <Route
        path="/faq"
        Component={() =>
          isMobile ? (
            <FAQ withoutBack />
          ) : (
            <Box
              sx={{
                pt: 4,
                pb: 4,
                maxHeight: '100vh',
                overflow: 'auto',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                '& .spacer': {
                  marginBottom: 4,
                },
              }}
            >
              <FAQ withoutBack />
            </Box>
          )
        }
      />
    ),
    contact: (isLb: boolean) => (
      <Route path="/contact" Component={() => <Navigate to={isLb ? '/auth/luckybird/faq' : '/auth/faq'} />} />
    ),
    logout: () => <Route path="/logout" Component={Logout} />,
    noMatch: () => <Route path="*" Component={NoMatch} />,
    loginLuckyBird: () => <Route path="/" Component={LuckyBirdCodeLogin} />,
  }

  useEffect(() => {
    ReactGA.event(location.pathname)
  }, [location])

  const LuckyBirdRouteElements = () => (
    <Routes>
      {sharedRoutes.help()}
      {sharedRoutes.faq()}
      {sharedRoutes.contact(true)}
      {sharedRoutes.privacy()}
      {sharedRoutes.logout()}
      {sharedRoutes.loginLuckyBird()}
      {sharedRoutes.noMatch()}
    </Routes>
  )

  return (
    <AuthProvider>
      <CustomAuthProvider>
        <Routes>
          {!subdomainInfo?.data?.type && sharedRoutes.noMatch()}
          <Route
            path="/auth/*"
            element={
              <AuthTemplate>
                <Routes>
                  {isGlobalPageLB ? (
                    <Route path="/luckybird/*" element={<LuckyBirdRouteElements />} />
                  ) : (
                    <>
                      <Route path="/" Component={LoginPage} />
                      <Route path="/login" Component={LoginPage} />
                      <Route path="/login/form" Component={LoginPage} />
                      <Route path="/login/okta-errors" Component={OktaErrors} />
                      <Route path="/activate" Component={Activate} />
                      <Route path="/activate-account" Component={ActivateAccount} />
                      <Route path="/activate-account/confirmation/:email" Component={Confirmation} />
                      <Route path="/reset-password" Component={ResetPassword} />
                      <Route path="/forgot-password" Component={ForgotPassword} />
                      <Route path="/redirect" Component={AuthRedirect} />
                      <Route path="/receive-voucher/:token" Component={ReceiveVoucher} />
                      <Route path="/luckybird/*" element={<LuckyBirdRouteElements />} />
                    </>
                  )}
                  {sharedRoutes.help()}
                  {sharedRoutes.faq()}
                  {sharedRoutes.contact(false)}
                  {sharedRoutes.privacy()}
                  {sharedRoutes.logout()}
                  {sharedRoutes.noMatch()}
                </Routes>
              </AuthTemplate>
            }
          />
          <Route
            path="/*"
            element={
              <SearchProvider>
                <ProtectedSwitch isLoading={isFetching} siteType={subdomainInfo?.data?.type} />
              </SearchProvider>
            }
          />
          {sharedRoutes.noMatch()}
        </Routes>
      </CustomAuthProvider>
    </AuthProvider>
  )
}
