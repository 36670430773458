import Grid from '@mui/material/Grid'
import { ServiceRequest } from 'pages/CMS/components/ContentBox/ServiceRequest/ServiceRequest'

export const HelpWrapper = () => {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={8}>
        <ServiceRequest withoutBack />
      </Grid>
    </Grid>
  )
}
